<script setup>
import { ModalsContainer } from "vue-final-modal";

useHead({
  htmlAttrs: {
    class: "layout-chat",
  },
});
</script>

<template>
  <main class="chat">
    <DefaultHeader class="header"></DefaultHeader>
    <slot />
    <ModalsContainer />
  </main>
</template>

<style lang="scss">
html.layout-chat {
  background-color: var(--default-body-background-color);
  color: var(--default-body-text-color);
}

html.layout-chat a {
  color: var(--default-body-text-color);
  text-decoration: none;
}
</style>

<style lang="scss" scoped>
.chat {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: stretch;
  align-self: center;
  width: 100%;
  max-height: 100%;
  text-align: center;
}
</style>
